import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, computed, inject, model, signal, viewChild } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { environment } from '@environments/environment';
import { IFieldNameKVP } from '@Reusables/reusable-pages/Report/report-extras/report.model';
import { TableRelationsService } from '@Services/table-relations.service';
import { UtilityService } from '@Services/utility.service';
import { ITableName } from '@Shared/models/index.model';
import { SharedModule } from '@Shared/shared.module';
import {
  BtnComponent,
  FindItemComponent,
  IFormSchema,
  IFormSchema2,
  TableCol
} from 'ets-fe-ng-sdk';
import { groupBy, uniqBy } from 'lodash-es';
import { lastValueFrom, map, merge, of, ReplaySubject, switchMap, tap } from 'rxjs';
import {
  DocumentationTableFieldsService,
  ITableFieldDocumentation,
} from '../documentation-table-fields/documentation-table-fields.service';
import { TablesByGroupComponent } from '../documentation-table-fields/tables-by-group/tables-by-group.component';

@Component({
  selector: 'coreapp-table-fields-dictionary',
  imports: [
    CommonModule,
    SharedModule,
    FindItemComponent,
    MatSidenavModule,
    TablesByGroupComponent
],
  templateUrl: './table-fields-dictionary.component.html',
  styleUrl: './table-fields-dictionary.component.scss',
})
export class TableFieldsDictionaryComponent {
  readonly service = inject(DocumentationTableFieldsService);
  readonly tableRelationsService = inject(TableRelationsService);
  readonly uS = inject(UtilityService);
  readonly cdr = inject(ChangeDetectorRef);
  readonly tableGroups = toSignal(this.tableRelationsService.getAllTableGroups());
  readonly allTables = toSignal(this.tableRelationsService.getAllTables());
  readonly emptyData = signal<any[]>([]);

  protected readonly groupMappedFields = model(true);
  protected readonly isEdit = model(false);
  readonly header = computed<string>(() =>
    this.selectedTable() ? this.selectedTable()._label : 'Recent Mappings',
  );
  readonly selectTable$ = new ReplaySubject<ITableName>(1);
  readonly selectedTable = toSignal(this.selectTable$);
  readonly tableMappings = toSignal<ITableFieldDocumentation[]>(
    this.selectTable$.pipe(
      tap(() => this.loading.set(true)),
      switchMap((table) =>
        table ? this.service.search({ tableName: table.primaryTable }).pipe(map((r) => r.content)) : of([]),
      ),
      tap(() => this.loading.set(false)),
    ),
  );
  readonly tableDescription = toSignal<string>(
    this.selectTable$.pipe(
      tap(() => this.loading.set(true)),
      switchMap((table) =>
        table
          ? this.tableRelationsService
              .searchTablesByName(table.primaryTable)
              .pipe(map((r) => (r.length ? r[0].description : null)))
          : of(null),
      ),
      tap(() => this.loading.set(false)),
    ),
  );
  searchQuery = signal<typeof this.tableSearchForm.value>(null);
  allTableFields = toSignal(this.tableRelationsService.getAllTableFields());
  fi = viewChild<FindItemComponent>('fi');
  readonly search = signal((query) => this.service.search({ ...query, ...this.searchQuery() }));
  readonly tableFields = toSignal<IFieldNameKVP[]>(
    this.selectTable$.pipe(
      switchMap((table) =>
        table ? this.tableRelationsService.getFieldNames(table.primaryTable, table.schema) : of([]),
      ),
    ),
  );
  readonly tableSearchForm = new FormGroup({
    fieldName: new FormControl<string>(null),
  });
  readonly loading = signal(false);
  readonly columns = computed<
    (TableCol<ITableFieldDocumentation> & {
      preventEdition?: boolean;
      readonly?: boolean;
      _noFormat?: boolean;
      _isSearchField?: boolean;
    })[]
  >(() => [
    {
      f: 'tableName',
      t: 'Table Name',
      type: 'textarea',
      hintFormatter: async (val) => {
        return await lastValueFrom(
          this.tableRelationsService.searchTablesByName(val.tableName).pipe(
            map((r) => {
              let desc: string;
              if (r.length) desc = r[0].description;
              return `${val.tableName} - ${desc}`;
            }),
          ),
        );
      },
      _isSearchField: true,
      preventEdition: true,
    },
    { f: 'fieldName', t: 'Field Name', _isSearchField: true, readonly: true, _noFormat: true },
    { f: 'description', t: 'Description', _noFormat: true },
  ]);
  readonly selectedTableCols = computed<TableCol[]>(() => {
    const isEdit = this.isEdit();
    return this.columns()?.filter((x) => !x.preventEdition);
  });
  readonly selectedTableFilterFields = signal<IFormSchema[]>([
    {
      field: 'fieldName',
      label: 'Field Name',
      type: 'text',
    },
  ]);
  get formFS() {
    return new FormGroup({
      fieldName: new FormControl<string>(null, Validators.required),
      description: new FormControl<string>(null),
      id: new FormControl<number>(null),
      tableName: new FormControl<string>(null, Validators.required),
      createdBy: new FormControl<string>(null),
      createdOn: new FormControl<string>(null),
      updatedBy: new FormControl<string>(null),
      updatedOn: new FormControl<string>(null),
    });
  }
  readonly formFSSignal = computed(() => this.formFS);

  readonly tableMappingsMerged = computed(() => {
    const tableFields = this.tableFields() || [],
      tableMappings = this.tableMappings() || [],
      selectedTable = this.selectedTable();
    return uniqBy(
      tableMappings.concat(
        tableFields?.map((x) => ({ fieldName: x.columnName, tableName: selectedTable.primaryTable })) || [],
      ),
      (i) => i.fieldName,
    ).sort3('fieldName');
  });
  readonly currentForms = computed(() => {
    const tableMappingsMerged = this.tableMappingsMerged();
    const form = new FormArray<typeof this.formFS>(
      tableMappingsMerged.map((x) => {
        const form = this.formFS;
        form.patchValue(x);
        return form;
      }),
    );

    return form;
  });
  readonly currentGroupedForms = toSignal(
    merge(toObservable(this.groupMappedFields), toObservable(this.tableMappingsMerged)).pipe(
      map(() =>
        Object.entries(
          groupBy(this.currentForms().controls, (i) =>
            i.controls.description.value?.length > 0 ? 'Mapped Fields' : 'Unmapped Fields',
          ),
        )
          .filter(([key, forms]) => key == 'Mapped Fields')
          .map(([key, forms]) => {
            return { label: key, forms: new FormArray(forms) };
          })
          .sort3('label'),
      ),
    ),
  );
  readonly sub = this.uS.createSubscriptionManager();

  constructor() {
    environment.wideScreen = true;
    this.sub.push(this.selectTable$.subscribe((r) => this.isEdit.set(false)));
  }

  ngOnDestroy(): void {
    environment.wideScreen = false;
    this.sub.clear();
  }

  filterTableFields() {
    const formValue = this.tableSearchForm.getRawValue();
    this.searchQuery.set(formValue);
    this.fi().search();
  }

  async downloadTable(btn?: BtnComponent) {
    btn?.setLoader(true);
    try {
      await this.uS.arrayToCSV(
        this.currentForms()
          .getRawValue()
          .map((x) => ({ fieldName: x.fieldName, tableName: x.tableName, description: x.description })),
        `${this.selectedTable().primaryTable} Field Descriptions`,
      );
    } catch (error) {
      this.uS.info(error, 0);
    }
    btn?.setLoader(false);
  }
}
